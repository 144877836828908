.app-header {
  background-color: #ffffff;
  color: black;
  font-family: "Rubik";
}

.app-logo {
  height: 56px;
  width: 56px;
  pointer-events: none;
}

.app-body {
  color: black;
  font-family: "Rubik";
}